import React, { useState } from "react";

import { LeaveTable } from "./leave-table";
import { ToggleButton } from "../toggle-button/ToggleButton";

import "./Leave.css";

const UPDATE_LEAVE_TABLE = "Modifier le tableau des congés";

export const Leave = () => {
  const [updateLeaveTable, setUpdateLeaveTable] = useState(false);

  return (
    <div className="Leave-container">
      {/* //! add clear planning button */}
      <ToggleButton
        label={UPDATE_LEAVE_TABLE}
        setIsToggled={setUpdateLeaveTable}
      />
      <div className="Leave-content">
        <LeaveTable updateLeaveTable={updateLeaveTable} />
      </div>
    </div>
  );
};
