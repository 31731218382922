import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { employee } from "../../../utils/employee";
import { capitalize } from "../../../utils/capitalize";
import { isDayOff, numberOfDays } from "../../../utils/dateConfig";

import "./LeaveTable.css";

export const LeaveTable = ({ updateLeaveTable }) => {
  const [calendar, setCalendar] = useState(employee);

  const days = [...new Array(numberOfDays)];

  const LeaveTableUpdatable = classNames("LeaveTable-updatable", {
    "LeaveTable-unupdatable": !updateLeaveTable,
  });
  const LeaveTableOffDayClass = (i) =>
    classNames({ "LeaveTable-dark": isDayOff(i) });
  const LeaveTableHoverClass = classNames({
    "LeaveTable-pointer": updateLeaveTable,
  });
  const LeaveTableDayIsOffClass = (name, i) =>
    classNames({ "LeaveTable-dayIsOff": calendar[name].off.includes(i + 1) });

  const handleClickOnCell = (name, i) => {
    if (isDayOff(i) || !updateLeaveTable) return;

    const currentPerson = calendar[name].off;
    const selectedDay = i + 1;

    const updatedPerson = currentPerson.includes(selectedDay)
      ? currentPerson.filter((day) => day !== selectedDay)
      : [...currentPerson, selectedDay];

    const updatedCalendar = { ...calendar, [name]: { off: updatedPerson } };

    setCalendar(updatedCalendar);
    localStorage.setItem("planning-absence", JSON.stringify(updatedCalendar));
  };

  useEffect(() => {
    const storedAbsenceCalendar = localStorage.getItem("planning-absence");
    if (storedAbsenceCalendar) {
      setCalendar(JSON.parse(storedAbsenceCalendar));
    }
  }, []);

  return (
    <table className={LeaveTableUpdatable}>
      <tbody>
        <tr className="LeaveTable-tr">
          <th className="LeaveTable-th"></th>
          {days.map((_, i) => (
            <th key={i} className={`${LeaveTableOffDayClass(i)} LeaveTable-th`}>
              {i + 1}
            </th>
          ))}
        </tr>
        {Object.keys(employee).map((name) => (
          <tr key={name} className="LeaveTable-name LeaveTable-tr">
            <th className="LeaveTable-th">{capitalize(name)}</th>
            {days.map((_, i) => (
              <td
                key={i}
                className={`${LeaveTableOffDayClass(
                  i
                )} ${LeaveTableHoverClass} ${LeaveTableDayIsOffClass(
                  name,
                  i
                )} LeaveTable-td`}
                onClick={() => handleClickOnCell(name, i)}
              />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
