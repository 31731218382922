import { DateTime } from "luxon";

const holidays = {
  1: [1],
  4: [10],
  5: [1, 8, 18, 29],
  7: [14],
  8: [15],
  11: [1, 11],
  12: [25],
}; // 2023

// export const holidays = {
//   1: [1],
//   4: [1],
//   5: [1, 8, 9, 20],
//   7: [14],
//   8: [15],
//   11: [1, 11],
//   12: [25],
// }; // 2024

const SATURDAY_WEEKDAY = 6;
const SUNDAY_WEEKDAY = 7;

const currentDate = DateTime.local();
const currentMonth = currentDate.c.month;
const currentYear = currentDate.c.year;
const numberOfDays = currentDate.endOf("month").c.day;

const generateDay = (i) => DateTime.local(currentYear, currentMonth, i);

const isOnWeekend = (i) =>
  generateDay(i).weekday === SATURDAY_WEEKDAY ||
  generateDay(i).weekday === SUNDAY_WEEKDAY;

const formatDate = (i) => DateTime.local(currentYear, currentMonth, i);

function currentWeekday(i) {
  return formatDate(i).toLocaleString({ weekday: "long", locale: "fr" });
}
function isDayOff(i) {
  return holidays[currentMonth].includes(i + 1) || isOnWeekend(i + 1);
}

function startOfWeek(i) {
  return generateDay(i).startOf("week").c.day;
}

export {
  currentDate,
  currentWeekday,
  holidays,
  isDayOff,
  numberOfDays,
  startOfWeek,
};
