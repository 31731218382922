import React from "react";
import classNames from "classnames";

import { capitalize } from "../../../utils/capitalize";
import {
  currentWeekday,
  isDayOff,
  numberOfDays,
  startOfWeek,
} from "../../../utils/dateConfig";
import { employee } from "../../../utils/employee";

import "./DutyTable.css";

const TASK_1 = "Boîte CAMNA + Fax";
const TASK_2 = "Téléphone (matin) + Courrier (après-midi)";
const TASK_3 = "Documents divers + Téléphone (après-midi)";

const tasks = [TASK_1, TASK_2, TASK_3];

function getRandomValues(list) {
  const shuffled = list.slice().sort(() => Math.random() - 0.5);
  return shuffled.slice(0, 3);
}

export const DutyTable = ({ currentMonthAndYear }) => {
  const days = [...new Array(numberOfDays)];

  const DutyTableOffDayClass = (i) =>
    classNames({ "DutyTable-dark": isDayOff(i) });

  //! add a randomize button
  //! store result in localStorage

  const randomDuty = (i) => {
    console.log(startOfWeek(i + 1));
    console.log(employee);

    // if (employee.off.includes(i + 1)) {
    //   console.log(Object.keys(employee));
    // }

    // has too be equal in the number of duties
    // if employee is off the day of the duty, don't put it in the pool
    // employee can't do the same task twice in the same week
    // isRpu will never do the TASK_3
    // (not mandatory: 4th duty on wednesday)

    return getRandomValues(Object.keys(employee));
  };

  return (
    <table className="DutyTable-table">
      <tbody>
        <tr className="DutyTable-tr">
          <th className="DutyTable-th">{currentMonthAndYear.toUpperCase()}</th>
          {tasks.map((task) => (
            <th key={task} className="DutyTable-th">
              {task}
            </th>
          ))}
        </tr>
        {days.map((_, i) => (
          <tr key={i} className="DutyTable-tr">
            <th className={`${DutyTableOffDayClass(i)} DutyTable-th`}>
              {capitalize(currentWeekday(i + 1))}&nbsp;
              {i + 1}
            </th>
            {randomDuty(i).map((name) => (
              <td
                key={name}
                className={`${DutyTableOffDayClass(i)} DutyTable-td`}
              >
                {!isDayOff(i) && capitalize(name)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
