import React from "react";

import "./ToggleButton.css";

export const ToggleButton = ({ setIsToggled, label }) => {
  return (
    <div className="ToggleButton-container">
      <label>
        <input type="checkbox" onClick={() => setIsToggled((prev) => !prev)} />
        <span />
      </label>
      <strong>{label}</strong>
    </div>
  );
};
