import React from "react";

import "./Duty.css";
import { DutyTable } from "./duty-table/DutyTable";

export const Duty = ({ currentMonthAndYear }) => {
  return (
    <div className="Duty-content">
      <DutyTable currentMonthAndYear={currentMonthAndYear} />
    </div>
  );
};
