import { currentDate } from "./utils/dateConfig";

import { Duty } from "./components/duty";
import { Leave } from "./components/leave";

import "./App.css";

function App() {
  const currentMonthAndYear = currentDate.toLocaleString({
    month: "long",
    year: "numeric",
    locale: "fr",
  });

  //! add a month selection

  return (
    <div className="App">
      <h1>{currentMonthAndYear.toUpperCase()}</h1>
      <Leave />
      <Duty currentMonthAndYear={currentMonthAndYear} />
    </div>
  );
}

export default App;
