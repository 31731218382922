export const employee = {
  laurence: { off: [] },
  catherine: { off: [] },
  sarra: { off: [] },
  karima: { off: [], isRpu: true },
  cindy: { off: [], isRpu: true },
  aissata: { off: [] },
  cathiana: { off: [] },
  foune: { off: [] },
  mailys: { off: [] },
  noara: { off: [] },
  véronique: { off: [] },
  laetitia: { off: [] },
  fatoumata: { off: [], isRpu: true },
  peggy: { off: [] },
  laura: { off: [] },
};
